import Swal from 'sweetalert2';
import api from '../../../shared/services/api';
import store from '../../store';
import {
    ClassificationState,
    GenericAction,
    CLASSIFICATION_TOGGLE_SUCCESS,
    CLASSIFICATION_TOGGLE_ERROR,
    CLASSIFICATION_SELECT,
    CLASSIFICATION_LOAD,
    CLASSIFICATION_RESET,
    CLASSIFICATION_CHANGE_CLASSROOM,
    CLASSIFICATION_CHANGE_SCHOOL,
    CLASSIFICATION_CLEAR,
    CLASSIFICATION_CHANGE_FILTER,
    CLASSIFICATION_CHANGE_SEARCH,
    CLASSIFICATION_LOAD_CLASSROOMS,
    CLASSIFICATION_CHANGE_STALEMATE,
    CLASSIFICATION_CHANGE_SEARCH_SCHOOL,
    CLASSIFICATION_LOAD_SEARCH_CLASSROOMS,
    CLASSIFICATION_CHANGE_LAST_SCHOOL,
    CLASSIFICATION_CLEAR_SELECTED_SCHOOL,
} from '../../types';

const initialState: ClassificationState = {
    data: [],
    selected: '',
    selectedSchool: '',
    previousSuccessfulSchool: {},
    classrooms: [],
    emptyClassrooms: true,
    loadedClassrooms: false,
    selectedClassroom: {},
    searchClassrooms: [],
    searchField: '',
    searchFilter: 'ALL',
    searchSchool: '',
    isEmpty: true,
    isLoaded: false,
    success: 'no',
    error: false,
    stalemate: [],
};

export default function (state = initialState, action: GenericAction) {
    switch (action.type) {
        case CLASSIFICATION_LOAD:
            const data = [...action.payload];

            let isEmpty = true;
            if (action.payload.length !== 0) {
                isEmpty = false;
            }

            return {
                ...state,
                data,
                isEmpty,
                isLoaded: true,
            };

        case CLASSIFICATION_LOAD_CLASSROOMS:
            const classrooms = [...action.payload];

            return {
                ...state,
                classrooms,
                loadedClassrooms: true,
            };

        case CLASSIFICATION_LOAD_SEARCH_CLASSROOMS:
            const searchClassrooms = [...action.payload];

            let emptyClassrooms = true;
            if (action.payload.length !== 0) {
                emptyClassrooms = false;
            }

            return {
                ...state,
                searchClassrooms,
                emptyClassrooms,
                loadedClassrooms: true,
                isLoaded: false,
                searchField: '',
            };

        case CLASSIFICATION_CLEAR:
            return {
                ...state,
                data: [],
                isEmpty: true,
                isLoaded: false,
                selected: '',
                selectedClassroom: {},
            };

        case CLASSIFICATION_TOGGLE_SUCCESS:
            return {
                ...state,
                success: action.payload,
            };

        case CLASSIFICATION_TOGGLE_ERROR:
            return {
                ...state,
                error: !state.error,
            };

        case CLASSIFICATION_SELECT:
            return {
                ...state,
                selected: action.payload,
            };

        case CLASSIFICATION_CHANGE_SCHOOL:
            return {
                ...state,
                selectedSchool: action.payload,
                selectedClassroom: {},
                isLoaded: false,
                loadedClassrooms: false,
            };

        case CLASSIFICATION_CHANGE_LAST_SCHOOL:
            return {
                ...state,
                previousSuccessfulSchool: action.payload,
            };

        case CLASSIFICATION_CHANGE_CLASSROOM:
            return {
                ...state,
                selectedClassroom: action.payload,
            };

        case CLASSIFICATION_CHANGE_FILTER:
            return {
                ...state,
                searchFilter: action.payload,
            };

        case CLASSIFICATION_CHANGE_SEARCH:
            return {
                ...state,
                searchField: action.payload,
            };

        case CLASSIFICATION_CHANGE_SEARCH_SCHOOL:
            return {
                ...state,
                searchSchool: action.payload,
                previousSuccessfulSchool: action.payload,
            };

        case CLASSIFICATION_CHANGE_STALEMATE:
            return {
                ...state,
                stalemate: action.payload,
            };

        case CLASSIFICATION_RESET:
            return { ...initialState };
            
        case CLASSIFICATION_CLEAR_SELECTED_SCHOOL:
                return {
                    ...state,
                    selectedSchool: '',
                    selectedClassroom: {},
                    searchSchool: '',
                };

        default:
            return state;
    }
}

export const changeSearchSchool = data => dispatch => {
    dispatch({ type: CLASSIFICATION_CHANGE_SEARCH_SCHOOL, payload: data });

    dispatch(fetchClassrooms(true));
};

export const changeSelectedSchool = data => dispatch => {
    dispatch({ type: CLASSIFICATION_CHANGE_SCHOOL, payload: data });

    dispatch(fetchClassrooms(false));
};

export const fetchClassrooms = (search: boolean) => async dispatch => {
    const token = store.getState().authReducer.user.token;
    const guid = search
        ? store.getState().classificationReducer.searchSchool.guid
        : store.getState().classificationReducer.selectedSchool.guid;

    api.get(`/classifications/${guid}`, {
        headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
        if (search) {
            dispatch({
                type: CLASSIFICATION_LOAD_SEARCH_CLASSROOMS,
                payload: response.data.classrooms,
            });
        } else {
            dispatch({
                type: CLASSIFICATION_LOAD_CLASSROOMS,
                payload: response.data.classrooms,
            });
        }
    });
};

export const updateClassification = (data: any) => async dispatch => {
    const token = store.getState().authReducer.user.token;

    await api
        .put(
            `/enrollments/${data.guid}`,
            { ...data },
            { headers: { Authorization: `Bearer ${token}` } },
        )
        .then(response => {
            const currentSelectedClassification = store.getState()
                .classificationReducer.selected;
            const updatedSelectedClassification = {
                ...currentSelectedClassification,
                ...data,
            };


            if (currentSelectedClassification.guid === data.guid) {
                dispatch({
                    type: CLASSIFICATION_SELECT,
                    payload: updatedSelectedClassification,
                });
            }

            dispatch(fetchClassrooms(true));
            dispatch(fetchClassrooms(false));

            if (data.quitAfter) {
                dispatch({
                    type: CLASSIFICATION_TOGGLE_SUCCESS,
                    payload: 'updateAlt',
                });
            } else {
                dispatch({
                    type: CLASSIFICATION_TOGGLE_SUCCESS,
                    payload: 'update',
                });
            }
        })
        .catch(error => {
            console.error(error);

            const fallbackClassroom = store
                .getState()
                .classificationReducer.selectedSchool.classrooms.find(
                    x =>
                        x.id ===
                        store.getState().classificationReducer.selected
                            .classroomId,
                );

            dispatch({
                type: CLASSIFICATION_CHANGE_CLASSROOM,
                payload: fallbackClassroom,
            });

            Swal.fire({
                icon: 'error',
                title: 'Erro ao atualizar dados',
                text: error.response?.data.message,
            });
        });
};

export const updateStudentInfo = (data: any) => async dispatch => {
    const token = store.getState().authReducer.user.token;

    await api
        .put(
            `/students/${data.guid}`,
            { ...data },
            { headers: { Authorization: `Bearer ${token}` } },
        )
        .then(response => {
            const currentSelectedClassification = store.getState()
                .classificationReducer.selected;
            const updatedSelectedClassification = {
                ...currentSelectedClassification,
                ...data,
            };

            if (currentSelectedClassification.guid === data.guid) {
                dispatch({
                    type: CLASSIFICATION_SELECT,
                    payload: updatedSelectedClassification,
                });
            }

            dispatch(fetchClassrooms(true));
            dispatch(fetchClassrooms(false));

            if (data.quitAfter) {
                dispatch({
                    type: CLASSIFICATION_TOGGLE_SUCCESS,
                    payload: 'updateAlt',
                });
            } else {
                dispatch({
                    type: CLASSIFICATION_TOGGLE_SUCCESS,
                    payload: 'update',
                });
            }
        })
        .catch(error => {
            console.error(error);

            const fallbackClassroom = store
                .getState()
                .classificationReducer.selectedSchool.classrooms.find(
                    x =>
                        x.id ===
                        store.getState().classificationReducer.selected
                            .classroomId,
                );

            dispatch({
                type: CLASSIFICATION_CHANGE_CLASSROOM,
                payload: fallbackClassroom,
            });

            Swal.fire({
                icon: 'error',
                title: 'Erro ao atualizar dados',
                text: error.response?.data.message,
            });
        });
};

export const transferClassification = (data: any) => async dispatch => {
    const token = store.getState().authReducer.user.token;

    await api
        .put(
            `/enrollments/transfer/${data.guid}`,
            { ...data },
            { headers: { Authorization: `Bearer ${token}` } },
        )
        .then(response => {
            // console.log(response);

            dispatch(fetchClassrooms(true));
            dispatch(fetchClassrooms(false));

            dispatch({
                type: CLASSIFICATION_CHANGE_LAST_SCHOOL,
                payload: store.getState().classificationReducer.selectedSchool,
            });

            dispatch({
                type: CLASSIFICATION_TOGGLE_SUCCESS,
                payload: 'update',
            });
        })
        .catch(error => {
            console.error(error);

            dispatch(
                changeSelectedSchool(
                    store.getState().classificationReducer
                        .previousSuccessfulSchool,
                ),
            );

            Swal.fire({
                icon: 'error',
                title: 'Erro na transferência',
                text: error.response?.data.message,
            });
        });
};

export const solveStalemate = (data: any) => async dispatch => {
    const token = store.getState().authReducer.user.token;

    await api
        .get(`/enrollments/stalemate/${data}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
            dispatch(fetchClassrooms(true));

            dispatch({ type: CLASSIFICATION_CHANGE_STALEMATE, payload: [] });

            dispatch({
                type: CLASSIFICATION_TOGGLE_SUCCESS,
                payload: 'stalemate',
            });
        })
        .catch(error => {
            console.error(error);

            dispatch({ type: CLASSIFICATION_CHANGE_STALEMATE, payload: [] });

            dispatch({
                type: CLASSIFICATION_TOGGLE_ERROR,
            });
        });
};

export const clearSelectedSchool = () => dispatch => {
    dispatch({ type: CLASSIFICATION_CLEAR_SELECTED_SCHOOL });
};